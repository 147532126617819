import UserUtils from '@/shared/utils/user-utils';
import Authorities from '../enums/authorities';
import {AuthorityContract} from './authority-types';

class AuthorityManager {
  private static instance: AuthorityManager | null = null;

  private userUtils: UserUtils;

  private services: AuthorityContract = {
    releaseMovement: false,
    scheduleMovement: false,
    cancelMovement: false,
    detailMovement: false,
    viewCriticMovement: false,
    insertWalletMovement: false,
    editMovement: false,
    viewWalletMovementAction: false,
    viewCriticAssociationAction: false,
    viewEndMovementAction: false,
    viewDocument: false,
    viewLogs: false,
    viewInternalNoteTab: false,
    viewButtonSelectedCriticismPending: false,
    viewButtonSelectedCriticismResolved: false,
    viewButtonResendMovement: false,
    viewRules: false,
    viewMovementRules: false,
    viewEligibilities: false,
    viewParametrizationBrokerAndInsuranceCarrier: false,
    viewProcedureRegisterSearch: false,
    viewFinancialGroupSync: false,
    viewInsuranceCarrierSync: false,
    viewInsuranceCriticismMaintenance: false,
    createInsuranceCriticismClassification: false,
    editInsuranceCriticismClassification: false,
    deleteInsuranceCriticismClassification: false,
    downloadInsuranceCriticismClassification: false,
    advancedMovement: false,
    editBeneficiaryDate: false,
    viewAllMovements: false,
    viewMovementSkipCriticism: false,
  };

  private constructor() {
    this.userUtils = new UserUtils();

    this.initServices();
  }

  public static getInstance(): AuthorityManager {
    if (!AuthorityManager.instance) {
      AuthorityManager.instance = new AuthorityManager();
    }
    return AuthorityManager.instance;
  }

  private initServices(): void {
    this.services = {
      releaseMovement: this.hasAccessToAuthority(Authorities.releaseMovement),
      scheduleMovement: this.hasAccessToAuthority(Authorities.scheduleMovement),
      cancelMovement: this.hasAccessToAuthority(Authorities.cancelMovement),
      detailMovement: this.hasAccessToAuthority(Authorities.detailMovement),
      viewCriticMovement: this.hasAccessToAuthority(Authorities.viewCriticMovement),
      insertWalletMovement: this.hasAccessToAuthority(Authorities.insertWalletMovement),
      editMovement: this.hasAccessToAuthority(Authorities.editMovement),
      viewWalletMovementAction: this.hasAccessToAuthority(Authorities.viewWalletMovementAction),
      viewCriticAssociationAction: this.hasAccessToAuthority(Authorities.viewCriticAssociationAction),
      viewEndMovementAction: this.hasAccessToAuthority(Authorities.viewEndMovementAction),
      viewDocument: this.hasAccessToAuthority(Authorities.viewDocument),
      viewLogs: this.hasAccessToAuthority(Authorities.viewLogs),
      viewInternalNoteTab: this.hasAccessToAuthority(Authorities.viewInternalNoteTab),
      viewButtonSelectedCriticismPending: this.hasAccessToAuthority(Authorities.viewButtonSelectedCriticismPending),
      viewButtonSelectedCriticismResolved: this.hasAccessToAuthority(Authorities.viewButtonSelectedCriticismResolved),
      viewButtonResendMovement: this.hasAccessToAuthority(Authorities.viewButtonResendMovement),
      viewRules: this.hasAccessToAuthority(Authorities.viewRules),
      viewMovementRules: this.hasAccessToAuthority(Authorities.viewMovementRules),
      viewEligibilities: this.hasAccessToAuthority(Authorities.viewEligibilities),
      viewParametrizationBrokerAndInsuranceCarrier: this.hasAccessToAuthority(Authorities.viewParametrizationBrokerAndInsuranceCarrier),
      viewProcedureRegisterSearch: this.hasAccessToAuthority(Authorities.viewProcedureRegisterSearch),
      viewFinancialGroupSync: this.hasAccessToAuthority(Authorities.viewFinancialGroupSync),
      viewInsuranceCarrierSync: this.hasAccessToAuthority(Authorities.viewInsuranceCarrierSync),
      viewInsuranceCriticismMaintenance: this.hasAccessToAuthority(Authorities.viewInsuranceCriticismMaintenance),
      createInsuranceCriticismClassification: this.hasAccessToAuthority(Authorities.createInsuranceCriticismClassification),
      editInsuranceCriticismClassification: this.hasAccessToAuthority(Authorities.editInsuranceCriticismClassification),
      deleteInsuranceCriticismClassification: this.hasAccessToAuthority(Authorities.deleteInsuranceCriticismClassification),
      downloadInsuranceCriticismClassification: this.hasAccessToAuthority(Authorities.downloadInsuranceCriticismClassification),
      advancedMovement: this.hasAccessToAuthority(Authorities.advancedMovement),
      editBeneficiaryDate: this.hasAccessToAuthority(Authorities.editBeneficiaryDate),
      viewAllMovements: this.hasAccessToAuthority(Authorities.viewAllMovements),
      viewMovementSkipCriticism: this.hasAccessToAuthority(Authorities.viewMovementSkipCriticism),
    };
  }

  private hasAccessToAuthority(authority: Authorities): boolean {
    return this.userUtils.hasAuthorityToAccess({ authority });
  }

  public getAuthorityReleaseMovement(): boolean {
    return this.services.releaseMovement;
  }

  public getAuthorityScheduleMovement(): boolean {
    return this.services.scheduleMovement;
  }

  public getAuthorityCancelMovement(): boolean {
    return this.services.cancelMovement;
  }

  public getAuthorityDetailMovement(): boolean {
    return this.services.detailMovement;
  }

  public getAuthorityViewCriticMovement(): boolean {
    return this.services.viewCriticMovement;
  }

  public getAuthorityInsertWalletMovement(): boolean {
    return this.services.insertWalletMovement;
  }

  public getAuthorityEditMovement(): boolean {
    return this.services.editMovement;
  }

  public getAuthorityViewWalletMovementAction(): boolean {
    return this.services.viewWalletMovementAction;
  }

  public getAuthorityViewCriticAssociationAction(): boolean {
    return this.services.viewCriticAssociationAction;
  }

  public getAuthorityViewEndMovementAction(): boolean {
    return this.services.viewEndMovementAction;
  }

  public getAuthorityViewDocument(): boolean {
    return this.services.viewDocument;
  }

  public getAuthorityViewLogs(): boolean {
    return this.services.viewLogs;
  }

  public getAuthorityViewInternalNoteTab(): boolean {
    return this.services.viewInternalNoteTab;
  }

  public getAuthorityViewButtonSelectedCriticismPending(): boolean {
    return this.services.viewButtonSelectedCriticismPending;
  }

  public getAuthorityViewButtonSelectedCriticismResolved(): boolean {
    return this.services.viewButtonSelectedCriticismResolved;
  }

  public getAuthorityViewButtonResendMovement(): boolean {
    return this.services.viewButtonResendMovement;
  }

  public getAuthorityViewRules(): boolean {
    return this.services.viewRules;
  }

  public getAuthorityViewMovementRules(): boolean {
    return this.services.viewMovementRules;
  }

  public getAuthorityViewEligibilities(): boolean {
    return this.services.viewEligibilities;
  }

  public getAuthorityViewParametrizationBrokerAndInsuranceCarrier(): boolean {
    return this.services.viewParametrizationBrokerAndInsuranceCarrier;
  }

  public getAuthorityViewProcedureRegisterSearch(): boolean {
    return this.services.viewProcedureRegisterSearch;
  }

  public getAuthorityViewFinancialGroupSync(): boolean {
    return this.services.viewFinancialGroupSync;
  }

  public getAuthorityViewInsuranceCarrierSync(): boolean {
    return this.services.viewInsuranceCarrierSync;
  }

  public getAuthorityViewInsuranceCriticismMaintenance(): boolean {
    return this.services.viewInsuranceCriticismMaintenance;
  }

  public getAuthorityCreateInsuranceCriticismClassification(): boolean {
    return this.services.createInsuranceCriticismClassification;
  }

  public getAuthorityEditInsuranceCriticismClassification(): boolean {
    return this.services.editInsuranceCriticismClassification;
  }

  public getAuthorityDeleteInsuranceCriticismClassification(): boolean {
    return this.services.deleteInsuranceCriticismClassification;
  }

  public getAuthorityDownloadInsuranceCriticismClassification(): boolean {
    return this.services.downloadInsuranceCriticismClassification;
  }

  public getAdvancedMovement(): boolean {
    return this.services.advancedMovement;
  }

  public getAuthorityEditBeneficaryDate(): boolean {
    return this.services.editBeneficiaryDate;
  }

  public getAuthorityViewAllMovements(): boolean {
    return this.services.viewAllMovements;
  }

  public getAuthorityViewMovementSkipCriticism(): boolean {
    return this.services.viewMovementSkipCriticism;
  }
}

const authorityManager = AuthorityManager.getInstance();

export default authorityManager;

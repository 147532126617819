enum Authorities {
  releaseMovement = 'sdi_painel_movimentacoes_cadastrais_liberar_movimentacao',
  scheduleMovement = 'sdi_painel_movimentacoes_cadastrais_acao_liberar_agendamento',
  cancelMovement = 'sdi_painel_movimentacoes_cadastrais_cancelar_movimentacao',
  detailMovement = 'sdi_painel_movimentacoes_cadastrais_detalhar_movimentacao',
  viewCriticMovement = 'sdi_painel_movimentacoes_cadastrais_visualizar_criticas_movimentacao',
  insertWalletMovement = 'sdi_painel_movimentacoes_cadastrais_inserir_carteirinha_movimentacao',
  editMovement = 'sdi_painel_movimentacoes_cadastrais_editar_movimentacao',
  viewWalletMovementAction = 'sdi_painel_movimentacoes_cadastrais_visualizar_acao_carteirinha_movimentacao',
  viewCriticAssociationAction = 'sdi_painel_movimentacoes_cadastrais_visualizar_acao_associar_criticas',
  viewEndMovementAction = 'sdi_painel_movimentacoes_cadastrais_visualizar_acao_finalizar_movimentacao',
  viewDocument = 'sdi_painel_movimentacoes_cadastrais_download_documentos_movimentacao',
  viewLogs = 'sdi_visualizacao_historico_movimentacoes',
  viewInternalNoteTab = 'sdi_painel_movimentacoes_cadastrais_visualizar_aba_notas_internas',
  viewButtonSelectedCriticismPending = 'sdi_painel_movimentacoes_cadastrais_visualizar_acao_marcar_selecionadas_pendentes',
  viewButtonSelectedCriticismResolved = 'sdi_painel_movimentacoes_cadastrais_visualizar_acao_marcar_selecionadas_resolvidas',
  viewButtonResendMovement = 'sdi_painel_movimentacoes_cadastrais_visualizar_acao_reenviar_movimentacao',
  viewRules = 'sdi_regras_gerais',
  viewMovementRules = 'sdi_regras_movimentacao',
  viewEligibilities = 'sdi_elegibilidade',
  viewParametrizationBrokerAndInsuranceCarrier = 'sdi_parametrizacao_tipos_movimentacao',
  viewProcedureRegisterSearch = 'sdi_cadastro_procedimento',
  viewFinancialGroupSync = 'sdi_configuracao_implantacao_grupo_financeiro',
  viewInsuranceCarrierSync = 'sdi_configuracao_implantacao_operadora',
  viewInsuranceCriticismMaintenance = 'sdi_manutencao_criticas_operadora_visualizar',
  createInsuranceCriticismClassification = 'sdi_manutencao_criticas_operadora_criar_classificacoes',
  editInsuranceCriticismClassification = 'sdi_manutencao_criticas_operadora_editar_classificacoes',
  deleteInsuranceCriticismClassification = 'sdi_manutencao_criticas_operadora_deletar_classificacoes',
  downloadInsuranceCriticismClassification = 'sdi_manutencao_criticas_operadora_baixar_classificacoes',
  advancedMovement = 'sdi_painel_movimentacoes_cadastrais_finalizar_movimentacao_todos_status',
  editBeneficiaryDate = 'sdi_beneficiario_editar_vigencia',
  viewAllMovements = 'sdi_painel_movimentacoes_cadastrais_visualizar_movimentacao',
  viewMovementSkipCriticism = 'sdi_mov_prosseguir_critica',
}

export default Authorities;
